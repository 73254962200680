(function(){
  document.querySelectorAll('[data-show-more-controller]').forEach(el => {
    const activator = el.querySelector('[data-show-more-activator]');
    const hiddenClass = el.dataset.showMoreHiddenClass || 'hidden';
    if (activator){
      activator.addEventListener('click', () => {
        el.querySelectorAll(`.${hiddenClass}`).forEach(hiddenEl => {
          hiddenEl.classList.remove(hiddenClass);
        });
        activator.classList.add('hidden');
      })
    }
  })
})();
